import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Modal, ModalBody, Label } from "reactstrap";
import { AttendanceContext } from "../../../Context/AttendanceContext";
import { AuthContext } from "../../../Context/AuthContext";
import { MessageContext } from "../../../Context/MessageContext";
import { getMessagesHistory } from "../../../Services/Contact-Service";
import { getSocketInstance } from "../../../Services/Socket-Service";
import MessageItem from "../Message/MessageItem";
import MessageHistory from "../Message/MessageHistory";
import DeleteMessageModal from "../../Modals/DeleteMessageModal";
import { Overlay } from "../Overlay";
import { timestampToDate, formatDateForId } from "../../../Utils/timestamp";
import { tagStyle } from "../Tags";
import { useToast } from "../Toast";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dropzone } from "@dropzone-ui/react";
import { getDepartments } from "../../../Services/Attendance-Service";
import TransferModal from "../../Modals/TransferModal";
import { getUsersByDepartment, postTransferAttendance, customerQueueTransfer } from "../../../Services/User-Service";
import { useAuth } from "../../../Context/AuthContext";
import { getDepartmentByUserId } from "../../../Services/Department-Service";
import ConfirmationModal from "../../Components/ConfirmationModal";
import EditMessageModal from "../../Modals/EditMessageModal";


function Chat({ customersRoute }) {
  const [inputMsg, setInputMsg] = useState("");
  const [page, setPage] = useState(2);
  const [lastMessage, setLastMessage] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [openDeleteMessageModal, setOpenDeleteMessageModal] = useState();
  const {
    messageHistory,
    setMessageHistory,
    activeChatCustomer,
    setActiveChatCustomer,
    showMessageHistoryModal,
    setShowMessageHistoryModal,
    resetMessages,
    setResetMessages,
    startAttendance,
    maxPages,
    setMaxPages,
    chatReadOnly,
    setAttachment,
    setOpenModalAttachment,
    attachment,
    loadingMessages,
  } = useContext(AttendanceContext);
  const { accessToken } = useContext(AuthContext);
  const { socketContext } = useContext(MessageContext);
  const socketInstance = getSocketInstance(accessToken);
  const scrollRef = useRef();
  const scrollModalRef = useRef();
  const { showWarningToast } = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const decodedToken = useAuth()

  const [choosedDepartment, setChoosedDepartment] = useState()
  const [choosedOperator, setChoosedOperator] = useState()
  const [privateMessage, setPrivateMessage] = useState('')
  const [departments, setDepartments] = useState([])
  const [operators, setOperators] = useState([])
  const [departmentsOperatorList, setDepartmentsOperatorList] = useState('')
  const [choosedDepartmentByOperator, setChoosedDepartmentOperator] = useState('')
  const [acceptedFiles, setAcceptedFiles] = useState([])
  const [openEditMessageModal, setOpenEditMessageModal] = useState()

  const [modal, setModal] = useState(false);
  const modalToggleTransfer = () => setModal(!modal);
  const modalToggleClose = () => setModal(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState('');

  const handleGetDepartments = async () => setDepartments(await getDepartments())
  const handleGetOperators = async (department) => {
    let res = await getUsersByDepartment(department)
    res = res.filter(operator => operator._id !== decodedToken._id)
    setOperators(res)
  }

  const handleOnChangeDepartment = (department) => {
    setChoosedDepartment(department)
    handleGetOperators(department)
  }

  const handleQueueTransfer = async () => {
    const payload = {
      cellPhone: activeChatCustomer.phone,
      body: {
        channelId: activeChatCustomer.channel.id,
        departmentId: choosedDepartment,
        privateMessage
      }
    }
    try {
      await customerQueueTransfer(payload)
    } catch (error) {
      console.error(error)
    } finally {
      setActiveChatCustomer({})
      modalToggleTransfer()
      modalToggle()
    }
  }

  const handleTransfer = async () => {
    const body = {
      departmentId: choosedDepartment,
      userId: choosedOperator || null,
      privateMessage,
    }
    try {
      await postTransferAttendance(activeChatCustomer.attendance.id, body)

    } catch (error) {
      console.error(error)
    }
    finally {
      modalToggle()
      setActiveChatCustomer({})
    }
  }

  const modalToggle = () => {
    setShowMessageHistoryModal(!showMessageHistoryModal);
    setActiveChatCustomer({});
    setDepartmentsOperatorList('')
    setChoosedDepartmentOperator('')
    setOpenConfirmationModal('')

  };
  const resetForm = () => {
    setChoosedDepartment(null)
    setChoosedOperator(null)
    setPrivateMessage(null)

  }

  const handleSubmit = (e) => {
    if (e.type === "vcard") {
      socketInstance.emit("chatbot_send_contact", {
        cellPhone: activeChatCustomer.phone,
        channelId: activeChatCustomer.channel.id,
        cellPhoneVCard: e.phone,
        nameVCard: e.name
      });
      return;
    }

    if (e.type === "myVcard") {
      socketInstance.emit("chatbot_send_my_contact", {
        cellPhone: activeChatCustomer.phone,
        channelId: activeChatCustomer.channel.id,
      });
      return;
    }

    if (e.type === "audio/mp3") {
      socketInstance.emit("chatbot_send_ptt", {
        cellPhone: activeChatCustomer.phone,
        channelId: activeChatCustomer.channel.id,
        type: e.type,
        content: e.content,
      });
      return;
    }

    if (!inputMsg || !socketInstance) return;

    socketInstance.emit("chatbot_send_message", {
      cellPhone: activeChatCustomer.phone,
      channelId: activeChatCustomer.channel.id,
      type: "chat",
      message: inputMsg,
    });
    setInputMsg("");
    scrollToBottom(e);
  };

  const handleReplyMessage = (e, replyingMessage) => {
    if (!inputMsg || !socketInstance) return;

    socketInstance.emit("chatbot_send_reply", {
      cellPhone: replyingMessage?.cellPhone,
      channelId: activeChatCustomer.channel.id,
      messageId: replyingMessage?.id || replyingMessage?._id,
      message: inputMsg,
    });
    setInputMsg("");
    scrollToBottom(e);
  };

  const handleChange = (newValue) => {
    setInputMsg(newValue);
  };

  const handleMessageHistory = async () => {
    if (lastMessage || maxPages < page) return;

    setShowLoading(true);
    let { messages, pages } = await getMessagesHistory(
      activeChatCustomer.phone,
      page,
      activeChatCustomer.channel.id,
      activeChatCustomer.dateFrom,
      activeChatCustomer.dateTo,

    );
    const uniqueDates = new Set();

    messages = messages.reduce((result, message) => {
      const timestamp = new Date(message.createdAt).toDateString();
      if (!uniqueDates.has(timestamp)) {
        let shouldSkip = false;

        messageHistory.forEach((msg) => {
          if (
            msg.uniqueDate &&
            msg.uniqueDate === timestampToDate(message.timestamp)
          ) {
            shouldSkip = true;
          }
        });

        if (!shouldSkip) {
          uniqueDates.add(timestamp);
          result.push({
            id: `${formatDateForId(timestampToDate(message.timestamp), 'uniqueDate_')}`,
            uniqueDate: timestampToDate(message.timestamp),
            type: "uniqueDate",
            timestamp: message.timestamp,
            createdAt: message.createdAt,
          });
        }
      }

      result.push(message);

      return result;
    }, []);

    setMaxPages(pages);
    setPage((prev) => prev + 1);

    if (messages.length === 0) {
      setLastMessage(true);
      return;
    }

    setTimeout(() => {
      setMessageHistory((prev) => {
        const uniqueNewMessages = messages
          .filter((newMsg) => {
            if (newMsg.uniqueDate) return newMsg;

            return !prev.some((prevMsg) => prevMsg._id === newMsg._id);
          })
          .map((msg) => ({
            formattedDate: timestampToDate(msg.timestamp),
            ...msg,
          }))
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        const messagesArray = [...uniqueNewMessages, ...prev]
          .filter((msg) => !msg.uniqueDate)
          .map((msg) => ({ ...msg }));
        const uniqueDates = [...uniqueNewMessages, ...prev]
          .filter((msg) => msg.uniqueDate)
          .map((msg) => {
            delete msg.formattedDate;
            return { ...msg };
          });

        const formattedUniqueDates = uniqueDates.map((dateObj) => {
          const parts = dateObj.uniqueDate.split("/");
          return `${parts[0]}/${parts[1]}/${parts[2]}`;
        });
        formattedUniqueDates.forEach((date) => {
          const firstItem = messagesArray.find(
            (item) => item.formattedDate === date
          );
          const itemToInsert = uniqueDates.find(
            (item) => item.uniqueDate === date
          );
          if (firstItem) {
            let index = messagesArray.indexOf(firstItem);
            messagesArray.splice(index, 0, itemToInsert);
          }
        });

        setShowLoading(false);
        return messagesArray;
      });
      if (showMessageHistoryModal) {
        setShowMessageHistoryModal(true);
      }
      scrollModalRef.current.scrollTop = 2000;
    }, 500);
  };

  const handleChatMessages = () => {

    if (socketContext.type === 'edited') {
      setMessageHistory(
        messageHistory
          .map((msg) => {
            if (msg._id === socketContext?._id) {
              msg.body = socketContext?.body;
              msg.isEdited = true;
              return msg;
            }

            return msg;
          })
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )
      );
      return
    }
    if (messageHistory.find((msg) => msg._id === socketContext.id)) return;
    if (
      socketContext.cellPhone &&
      activeChatCustomer.phone === socketContext.cellPhone &&
      activeChatCustomer.channel.id === socketContext.channel.id
    ) {
      setMessageHistory((prev) =>
        [...prev, socketContext].sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
      );

      if (!activeChatCustomer.attendance.type) {
        return;
      }

      socketInstance.emit("chatbot_send_seen", {
        cellPhone: activeChatCustomer.phone,
        channelId: activeChatCustomer.channel.id,
      });
      scrollToBottom();
      return;
    }

    if (
      !socketContext.cellPhone &&
      activeChatCustomer.from === socketContext.to &&
      activeChatCustomer.channel.id === socketContext.channel.id
    ) {
      setMessageHistory((prev) =>
        [...prev, socketContext].sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
      );
      scrollToBottom();
      return;
    }
  };

  const handleDeleteChatMessage = (message) => {
    if (message?.origin === "attendance" || message?.origin === "chatBot") {
      socketInstance.emit("chatbot_send_message_revoke", {
        cellPhone: message.cellPhone,
        channelId: activeChatCustomer.channel.id,
        messageId: message.id || message._id,
      });
    }

    setOpenDeleteMessageModal(null);
  };

  const handleEditChatMessage = (message, inputMsg) => {
    if (message?.origin === "attendance" || message?.origin === "chatBot") {
      socketInstance.emit('chatbot_send_message_edit', {
        cellPhone: message?.cellPhone,
        message: inputMsg,
        messageId: message?.id || message?._id,
        channelId: activeChatCustomer?.channel?.id,
      });
    }

    setOpenEditMessageModal(null)
  }

  const handleAckChatMessage = (id, ack) => {
    const updatedMessageHistory = messageHistory
      .map((msg) => {
        if (msg._id === id) {
          return {
            ...msg,
            ack: ack || 2,
          };
        }
        return msg;
      })
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

    setMessageHistory(updatedMessageHistory);
  };

  const handleReactionChatMessage = (id, reaction) => {
    const updatedMessageHistory = messageHistory
      .map((msg) => {
        if (msg && msg._id === id) {
          const reactions = msg.reactions || [];
          const reactionIndex = reactions.findIndex(
            (r) => r.senderId === reaction.senderId
          );
          if (reactionIndex !== -1) {
            reactions[reactionIndex] = {
              reaction: reaction.reaction,
              senderId: reaction.senderId,
            };
          } else {
            reactions.push({ reaction: reaction.reaction, senderId: reaction.senderId });
          }
          return {
            ...msg,
            reactions: reactions,
          };
        }
        return msg;
      })
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

    setMessageHistory(updatedMessageHistory);
  };

  const handleStartAttendance = (departmentId) => {
    if (history.location.pathname.includes("contatos")) {
      return;
    }
    if (!departmentId && activeChatCustomer.attendance?.type === 'auto') {
      setOpenConfirmationModal({ open: true, info: departmentsOperatorList })
      return
    }
    startAttendance(activeChatCustomer, departmentId.value);
    setResetMessages(true);
    modalToggle();
  };

  const typeForChatMessages = [
    "chat",
    "image",
    "document",
    "audio",
    "video",
    "ptt",
    "vcard",
    "edited"
  ];

  const getWSEvent = () => {
    if (socketContext) {
      if (typeForChatMessages.includes(socketContext.type)) {
        handleChatMessages();
        scrollToBottom();
      }

      if (socketContext.type === "delete") {
        handleDeleteChatMessage();

        setMessageHistory(
          messageHistory
            .map((msg) => {
              if (msg._id === socketContext?.id && !msg.uniqueDate) {
                msg.deleted = true;
                return msg;
              }

              return msg;
            })
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            )
        );
      }

      if (socketContext.type === 'edited') {
        handleEditChatMessage()

        setMessageHistory(
          messageHistory
            .map((msg) => {
              if (msg._id === socketContext?.id) {
                msg.body = socketContext.body;
                msg.isEdited = true;
                return msg;
              }

              return msg;
            })
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            )
        );
      }


      if (socketContext.type === "ack") {
        handleAckChatMessage(socketContext.id, socketContext.ack);
      }

      if (socketContext.type === "reaction") {
        handleReactionChatMessage(socketContext.id, { reaction: socketContext.reaction, senderId: socketContext.senderId });
      }
    }
  };

  const onDropFile = (droppedFile) => {
    if (!droppedFile.length) return;

    const { errors, file } = droppedFile[0];

    if (errors?.length) {
      showWarningToast("Tipo de arquivo não permitido.");
      return;
    }

    setAttachment(file);
    setOpenModalAttachment(true);
  };

  const scrollToBottom = (e) => {
    e && e.preventDefault();

    setTimeout(() => {
      if (!scrollRef.current?.scrollTop) return;
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight + 500;
    }, 100);
  };

  const operatorStatusIndicator = (status) => {
    if (status === 'online') {
      return <span>🟢</span>
    }
    return <span>🔴</span>
  }

  const getDepartmentByIdOperator = async (id) => {
    const response = await getDepartmentByUserId(id)
    setDepartmentsOperatorList(response.map(department =>
    ({
      value: department._id,
      label: department.name,
    })));
    if (response.length === 1) {
      setChoosedDepartmentOperator({
        value: response[0]?._id,
        label: response[0]?.name
      })
    }
  }

  const getAcceptedFiles = () => {
    const mimeString = acceptedFiles.reduce((acc, curr, index, array) => {
      return acc + curr + (index < array.length - 1 ? ', ' : '');
    }, '{') + '}';
    return mimeString
  }
  let acceptedFilesFormated;
  useEffect(() => {
    acceptedFilesFormated = getAcceptedFiles()
  }, [acceptedFiles])


  useEffect(() => {
    const filteredMessages = messageHistory.filter((msg) => !msg.uniqueDate);
    if (
      scrollRef.current &&
      filteredMessages.length <= 20 &&
      !socketContext?.reaction
    ) {
      setTimeout(() => {
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      });
    }

  }, [messageHistory]);

  useEffect(() => {
    if (scrollModalRef.current) {
      setTimeout(() => {
        scrollModalRef.current.scrollTop = scrollModalRef.current?.scrollHeight;
      });
    }
  }, [scrollModalRef.current]);

  useEffect(() => {
    getWSEvent();
  }, [socketContext, activeChatCustomer.phone]);

  useEffect(() => {
    if (resetMessages) {
      setPage(2);
      setLastMessage(false);
      setShowLoading(false);
      setResetMessages(false);
    }
  }, [resetMessages]);

  useEffect(() => {

    if (!modal) {
      resetForm()
    }
    modal && handleGetDepartments()
  }, [modal])

  const messagesViewModal = useMemo(() => {
    return messageHistory.map((message, i) => {
      return (
        <React.Fragment key={i}>
          <MessageItem
            message={message}
            activeChatCustomer={activeChatCustomer}
            i={i}
            showMessageHistoryModal={showMessageHistoryModal}
            replyingMessage={null}
            deleteMessage={null}
            editMessage={null}
          />
        </React.Fragment>
      );
    });
  }, [messageHistory, activeChatCustomer, showMessageHistoryModal]);

  const MessageHistoryModal = useMemo(() => {
    if (activeChatCustomer.attendance?.type === 'auto' && departmentsOperatorList?.length === 0) {
      const id = decodedToken._id
      getDepartmentByIdOperator(id);
    }

    const isMobile = document.body.clientWidth < 768
    return (
      <Modal
        zIndex={9999}
        isOpen={showMessageHistoryModal}
        toggle={loadingMessages || messageHistory[1]?.cellPhone !== activeChatCustomer.phone ? modalToggleClose : modalToggle}
        style={!isMobile ? { minWidth: 700 } : { height: '100vh', display: 'flex' }}
      >
        <ModalBody style={{ height: "90vh", backgroundColor: "#e6e6e6" }}>
          <header className="d-flex align-items-center justify-content-between">
            <div style={{ width: '80%' }}>
              <p style={{
                fontSize: 18,
                fontWeight: "bold",
                margin: 0,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
                {activeChatCustomer.name}<br />
              </p>
              <div
                className="d-flex align-items-center"
                style={{ gap: 10 }}
              >
                <small
                  className="text-muted"
                  style={{ fontStyle: "italic" }}
                >
                  {activeChatCustomer.phoneFormatted}
                </small>
                {activeChatCustomer.externalIds
                  ? activeChatCustomer.externalIds.map((externalId, i) => (
                    <div
                      key={i}
                      className={`bg-dark-bright`}
                      style={{ ...tagStyle, fontSize: 10, padding: "1px 0" }}
                    >
                      <span
                        style={{
                          position: "relative",
                          bottom: -1,
                          fontWeight: "bold",
                        }}
                        title={externalId}
                      >
                        Cód.: {externalId}
                      </span>
                    </div>
                  ))
                  : null}
              </div>
            </div>
            <button
              onClick={modalToggle}
              className="btn btn-light"
            >
              <i className="ti ti-close"></i>
            </button>
          </header>
          {showLoading && !lastMessage ? (
            <div
              style={{ textAlign: "center", position: "absolute", left: "50%", zIndex: 999 }}
            >
              <i
                className="fa fa-spinner"
                style={{ animation: "spin 3s linear infinite", fontSize: 42 }}
              />
            </div>
          ) : null}
          <div
            className="layout"
            style={{ filter: "initial", height: "100%" }}
          >
            <div
              className="content pt-0"
              style={{
                flexDirection: "column",
                padding: "30px 0",
                paddingBottom: 50,
              }}
            >
              <div
                className="chat"
                style={{ height: "90%" }}
              >
                <div className="chat-body">
                  <PerfectScrollbar
                    containerRef={(ref) =>
                      ref ? (scrollModalRef.current = ref) : null
                    }
                    onScrollY={(ref) =>
                      ref.scrollTop === 0 ? handleMessageHistory() : null
                    }
                  >
                    <div className="messages" style={{ paddingBottom: 5 }}>
                      {!loadingMessages ? (
                        messageHistory && messageHistory[1]?.cellPhone === activeChatCustomer?.phone ? (
                          messagesViewModal
                        ) : (
                          <>
                            {messageHistory.length > 0 ? (
                              <div style={{ textAlign: "center", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                                <i className="fa fa-spinner" style={{ animation: "spin 3s linear infinite", fontSize: 42 }} />
                              </div>
                            ) : (
                              <div className="d-flex flex-column align-items-center " style={{ height: '50%', margin: 'auto', marginTop: '20%' }}>
                                <i className="fa fa-exclamation-circle" style={{ fontSize: "5em", color: "#aeaeae" }}></i>
                                <p style={{ fontSize: "1.2em" }}>No momento não há registros.</p>
                              </div>
                            )}
                          </>
                        )
                      ) : (
                        <div style={{ textAlign: "center", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                          <i className="fa fa-spinner" style={{ animation: "spin 3s linear infinite", fontSize: 42 }} />
                        </div>
                      )}
                    </div>
                  </PerfectScrollbar>
                </div>
              </div>
              {!activeChatCustomer.serviceReport && !history.location.pathname.includes("contatos") && (
                <div className="d-flex" style={{ padding: '10px', alignItems: 'center' }}>
                  <div className="text-right ml-auto d-flex" style={{ alignItems: 'baseline' }}>
                    {activeChatCustomer.attendance?.type !== 'auto' && (
                      <button
                        type="button"
                        className="btn btn-secondary mt-md-4 mt-lg-4 py-sm-2 mr-1"
                        onClick={() => modalToggleTransfer(true)}
                        style={{ padding: "5px 10px" }}
                        id="Tooltip-Voice-Call2"
                      >
                        Transferir atendimento
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary mt-md-4 mt-lg-3 py-sm-2"
                      data-dismiss="modal"
                      style={{ padding: "5px 10px" }}
                      onClick={() => handleStartAttendance(choosedDepartmentByOperator)}
                    >
                      Iniciar atendimento
                    </button>
                    <ConfirmationModal
                      openConfirmationModal={openConfirmationModal}
                      setOpenConfirmationModal={setOpenConfirmationModal}
                      setChoosedOption={setChoosedDepartmentOperator}
                      choosedOption={choosedDepartmentByOperator}
                      listOptions={departmentsOperatorList}
                      handleStartAttendance={() => handleStartAttendance(choosedDepartmentByOperator)}
                      messageModal="Selecione o departamento para iniciar o atendimento"
                    />
                    {modal && (
                      <TransferModal
                        activeChatCustomer={activeChatCustomer}
                        departments={departments}
                        operators={operators}
                        choosedDepartment={choosedDepartment}
                        choosedOperator={choosedOperator}
                        handleOnChangeDepartment={handleOnChangeDepartment}
                        setChoosedOperator={setChoosedOperator}
                        handleTransfer={activeChatCustomer.attendance?.id ? handleTransfer : handleQueueTransfer}
                        privateMessage={privateMessage}
                        setPrivateMessage={setPrivateMessage}
                        operatorStatusIndicator={operatorStatusIndicator}
                        modal={modal}
                        modalToggleTransfer={modalToggleTransfer}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }, [showMessageHistoryModal,
    messagesViewModal,
    showLoading,
    lastMessage,
    modal,
    departments,
    operators,
    choosedDepartment,
    choosedOperator,
    privateMessage,
    loadingMessages,
    messageHistory,
    activeChatCustomer,
    choosedDepartmentByOperator,
    departmentsOperatorList,
    openConfirmationModal]);

  if (customersRoute)
    return showMessageHistoryModal ? MessageHistoryModal : <></>;

  return (
    <>
      <Overlay />
      <Dropzone
        disabled={!activeChatCustomer.name || attachment.size}
        multiple={false}
        onChange={onDropFile}
        footer={false}
        header={false}
        clickable={false}
        label=""
        accept={acceptedFilesFormated}
        autoClean
      >
        <div
          className="chat"
          style={{ height: "100%" }}
        >
          {activeChatCustomer.name ? (
            showMessageHistoryModal ? (
              MessageHistoryModal
            ) : (
              <MessageHistory
                lastMessage={lastMessage}
                showLoading={showLoading}
                messageHistory={messageHistory}
                activeChatCustomer={activeChatCustomer}
                handleMessageHistory={handleMessageHistory}
                handleChange={handleChange}
                handleSubmit={(e, replyingMessage) =>
                  replyingMessage
                    ? handleReplyMessage(e, replyingMessage)
                    : handleSubmit(e)
                }
                deleteMessage={(message) =>
                  setOpenDeleteMessageModal({ open: true, message })
                }
                editMessage={(message) =>
                  setOpenEditMessageModal({ open: true, message })
                }
                inputMsg={inputMsg}
                scrollRef={scrollRef}
                chatReadOnly={chatReadOnly}
                acceptedFiles={acceptedFiles}
                setAcceptedFiles={setAcceptedFiles}
              />
            )
          ) : (
            <div className="chat-body no-message">
              <div className="no-message-container">
                <i className="fa fa-comments-o"></i>
                <p>Selecione uma conversa.</p>
              </div>
            </div>
          )}
          <DeleteMessageModal
            openDeleteMessageModal={openDeleteMessageModal}
            setOpenDeleteMessageModal={setOpenDeleteMessageModal}
            deleteMessage={handleDeleteChatMessage}
          />
          <EditMessageModal
            openEditMessageModal={openEditMessageModal}
            setOpenEditMessageModal={setOpenEditMessageModal}
            editMessage={handleEditChatMessage}
          />
        </div>
      </Dropzone>
    </>
  );
}

export default Chat;